import {postMediaData} from 'helpers/apiHelper'
import { MAIN_PATH, UPLOAD_FILE_PATH } from './apiPath'
import { uploadFile } from "store/actions/file";

//upload image
export function uploadImage_(value, callback) {
  let url = `${MAIN_PATH}${UPLOAD_FILE_PATH}`;
  return (dispatch) => postMediaData(url, value)
      .then((data) => {
        dispatch(uploadFile(data, callback));
        return data;
      });
}
