//AUTH
export const login = '/login';
export const forgotPassword = "/forgot-password";
export const resetPassword = "/reset-password/:token";
export const signup = "/signup";
export const selectProfile = "/select-profile";

//dashboard
export const dashboard = '/dashboard';

// switch profile
export const switchProfileDashboard = "/dashboard/switch-profile";

// onboarding
export const dashboardTermsCondition = "/dashboard/terms-and-conditions";
export const privacyPolicyAgreement = "/dashboard/privacy-policy";

// terms & conditions
export const termsConditions = "/terms-conditions";

// privacy policy
export const privacyPolicy = "/privacy-policy";

// food research
export const foodResearch = "/food-research";

// my account
export const myAccount = "/my-account";

// recommendation
export const recommendation = "/recommendation";

// action plans
export const actionPlans = "/action-plans";

// todo listing
export const todo = "/to-do";

// view todo details
export const viewTodo = "/to-do/:id/form";

// my log 
export const myLog = "/my-log";
export const logMeal = "/log-meal";
export const logFeeling = "/log-feeling";
export const viewLog = "/view-log";

// appointment calendar
export const appointmentForm = "/public/calendar/:id";
export const appointmentPaymentSuccess = "/appointment/payment-success/:apptId";

// public view appointment
export const viewAppointmentPublic = "/public/appointment/:uuid"

//onboarding
export const dashboard_terms_condition = '/dashboard/terms-condition';

//subscription
export const dashboard_subscription = '/dashboard/subscription';

// packages
export const purchasePackage = "/public/package";
export const purchasePackageSuccess = "/public/package/payment-success/";

// public todo submission
export const publicTodoForm = "/public/forms/:todoId";

// public privacy policy acceptance 
export const publicPrivacyPolicyAccept = "/public/privacy-policy";

// documents
export const documents = "/documents";