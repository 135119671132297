import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Dropzone from "components/shared/Dropzone";
import { Formik } from "formik";
import * as Yup from "yup";
import { ALLOWED_FILE_TYPES } from "common/constants";

const validation = Yup.object({
    document_name: Yup.string().required("Document title is Required")
});


const maxUploadSize = 5e+6; // in bytes

const AddDocumentModal = ({
  showPopup,
  setShowPopup,
  handleSubmit,
}) => {
  const togglePopup = () => setShowPopup(!showPopup);

  const initialValues = {
    file: "",
    url: "",
    size: "",
    size_bytes: "",
  };

  return (
    <>
      <Modal
        show={showPopup}
        onHide={togglePopup}
        backdrop="static"
        keyboard={false}
        centered
        className="UploadDocPop FormPop"
      >
        <Modal.Header className="text-center justify-content-center">
          <Modal.Title className="fw-bold">FILES TO UPLOAD</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-lg-4">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Row className="justify-content-center">
                  <Col lg="12" className="my-2">
                    <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                      Document Name*
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.document_name && "is-invalid"}`}
                      name="document_name"
                      onChange={handleChange}
                      placeholder="Document name"
                      onBlur={handleBlur}
                      value={values.document_name}
                      autoComplete="off"
                    />
                    {errors.document_name && touched.document_name && (
                      <p className="invalid-feedback">{errors.document_name}</p>
                    )}
                  </Col>
                  <Col lg="12" className="my-2">
                    <Dropzone
                      file={values.name} // Provide the file as an object
                      onBlur={handleBlur}
                      onChange={(file, url, extension, file_type, size) => {
                        setFieldValue("file", file);
                        setFieldValue("url", url);
                        setFieldValue("extension", extension);
                        setFieldValue("file_type", file_type);
                        setFieldValue("size", size);
                      }}
                      allowType={ALLOWED_FILE_TYPES}
                      allowTypeError={ALLOWED_FILE_TYPES.join(", ")}
                      // extension_={extension}
                      imageRemoveButton={true}
                      maxFileSize={maxUploadSize}
                    />
                    <p className="py-2 m-0 text-center">
                      <i>
                        Combined file upload size can't be larger than 5 MB
                      </i>
                    </p>
                    {/* </Dropzone> */}
                  </Col>
                  <Col lg="12">
                    <div className="d-flex align-items-center justify-content-center gap-10 FormbtnWrpper">
                      <Button
                        onClick={togglePopup}
                        className="d-flex align-items-center justify-content-center commonBtn"
                      >
                        Cancel
                      </Button>
                      <Button
                        // onClick={handleUploadDoc}
                        type="submit"
                        // loading={loader}
                        disabled={isSubmitting}
                        className="d-flex align-items-center justify-content-center commonBtn"
                      >
                        { isSubmitting ? "Please wait..." : "Upload Docs"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDocumentModal;
