import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import persistStore from './persistStore';
//imported
import Auth from './Auth';
import Profile from './Profile';
import ToDo from './Todo';
import Recommendation from './Recommendation';
import Package from './Package';
import Appointment from './Appointment';
import ActionPlan from './ActionPlan';
import ContentPage from './ContentPage';
import AccountSetting from './AccountSetting';
import MyLog from './MyLog';
import App from './App';
import Document from "./Document";

const RootReducer = combineReducers({
		Auth,
		persistStore,
		Profile,
		ToDo,
		Recommendation,
		Package,
		Appointment,
		ActionPlan,
		ContentPage,
		AccountSetting,
		MyLog,
		Document,
		App,
		form: formReducer,
	});
export default RootReducer;
