import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { DOCUMENTS_ADD, DOCUMENTS_DELETE, DOCUMENTS_LIST, DOCUMENTS_MARK_VIEWED, DOCUMENTS_UPDATE, GET_DASHBOARD_DATA, MAIN_PATH  } from './apiPath'
import { getDocuments } from "store/actions/document";

// get Appointment type list
export function getDocumentList(payload, callback) {
  let url = `${MAIN_PATH}${DOCUMENTS_LIST}`;
  return (dispatch) => postData(url, payload)
    .then((data) => {
      dispatch(getDocuments(data, callback));
      return data;
    });
}

// add document
export function addDocument(values, callback) {
  let url = `${MAIN_PATH}${DOCUMENTS_ADD}`;
  return postData(url, values);
}

// update document
export function updateDocument(data, callback) {
  let { id, values } = data;
  let url = `${MAIN_PATH}${DOCUMENTS_UPDATE}/${id}`;
  return postData(url, values);
}


// delete document
export function deleteDocument(id) {
  let url = `${MAIN_PATH}${DOCUMENTS_DELETE}/${id}`;
  return  deleteData(url);
}

// mark document viewed
export function markDocumentViewed(data, callback) {
  let { id, values } = data;
  let url = `${MAIN_PATH}${DOCUMENTS_MARK_VIEWED}/${id}`;
  return postData(url, values);
}