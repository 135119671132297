import { View, Text } from "@react-pdf/renderer";
import Html from "react-pdf-html";

const CustomRenderer = (props) => {
  return (
    <View>
      <Text {...props}>{props.children} {"\n".repeat(props.newLines || 2)}</Text>
    </View>
  )
}

const HtmlRenderer = ({ content, fontSize = 10 }) => {
  return (
    <Html
      style={{ fontSize }}
      renderers={{
        "p": (props) => <CustomRenderer {...props} />,
        "h1": (props) => <CustomRenderer {...props} />,
        "h2": (props) => <CustomRenderer {...props} />,
        "h3": (props) => <CustomRenderer {...props} />,
        "h4": (props) => <CustomRenderer {...props} />,
        "h5": (props) => <CustomRenderer {...props} />,
        "h6": (props) => <CustomRenderer {...props} />,
        "br": () => `\n`,
      }}
    >
      {content}
    </Html>
  )
}

export default HtmlRenderer;