import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Document, Page, Text, StyleSheet, View, Image, pdf } from "@react-pdf/renderer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { decode } from "html-entities";
import {
  chartOptions,
  dangerSignAnnotation,
  chartMax,
  chartMaxSuper,
  lineAnnotation,
} from "./ChartConfig";
import moment from "moment";
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

export const LineChart = forwardRef(function Chart({ chartData, height, screenSize, isPdf }, ref) {
  const [isLoading, setLoading] = useState(false);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState({
    devicePixelRatio: isPdf === true ? 3 : window.devicePixelRatio,
    ...chartOptions
  });

  const chartRef = useRef();

  // if (!chartData) {
  //   return "";
  // }
  

  // if (chartData) {
  const labels = chartData?.line?.map((row) => {
    return `${decode(row.title)}`;
  });

  let dangerLabels = {};
  const dataset = chartData?.line?.map((row, i) => {
    let percentOfMax = row.percentOfMax;
    let origValue = percentOfMax * chartMax;
    let lineValue = origValue;

    // keeping a reserved space for the danger sign so adding +5
    if (origValue + 5 > chartMaxSuper) {
      lineValue = chartMaxSuper;
    }
    
    if(percentOfMax == 0.99) {
      dangerLabels[`danger_${i}`] = dangerSignAnnotation(
        i,
        origValue + 5 > chartMaxSuper ? chartMaxSuper - 15 : lineValue
      );
    }
    return lineValue;
  });

  const chartLabels = {};
  for (let i = 0; i < labels?.length; i++) {
    chartLabels[`chartLabel${i}`] = {
      type: "label",
      xValue: i,
      yValue: 2.5,
      content: [chartData?.line[i]?.rawTotal],
      font: {
        size: 15,
      },
      // rotation: 270,
      position: "center",
      align: "left",
    };
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: dataset,
        backgroundColor: chartData?.line?.length && chartData?.line[0].color,
        borderColor: chartData?.line?.length && chartData?.line[0].color,
        pointRadius: 6,
        pointHoverRadius: 8,
        skipNull: false,
      },
    ],
  };

  let xAdjustValue = (function () {
    switch (screenSize) {
      case "extra-small":
        return -15;
      case "small":
        return -18;
      case "medium":
        return -20;
      case "large":
        return -25;
      default:
        return -25;
    }
  })();

  let newOptions = {
    ...options,
    scales: {
      ...options.scales,
      x: {
        ...options.scales.x,
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
          padding: 10,
          color: "#000",
          font: {
            size: 15,
          }
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      ...options.plugins,
      tooltip: {
        callbacks: {
          label: function (context) {
            if (chartData) {
              let label = chartData.line[context.dataIndex]?.rawTotal;
              return label;
            }
          },
          title: function (context) {
            if (chartData) {
              let title = chartData.line[context[0].dataIndex]?.title;
              return decode(title);
            }
          },
        },
      },
      annotation: {
        ...options.plugins.annotation,
        annotations: {
          ...options.plugins.annotation.annotations,
          ...chartLabels,
          ...dangerLabels,
          label1: {
            ...lineAnnotation,
            xAdjust: xAdjustValue,
            yValue: chartMax - chartMax / 1.2,
            content: ["Low Priority"],
          },
          label2: {
            ...lineAnnotation,
            xAdjust: xAdjustValue,
            yValue: chartMax - chartMax / 2,
            content: ["Medium Priority"],
          },
          label3: {
            ...lineAnnotation,
            xAdjust: xAdjustValue,
            yValue: chartMax / 1.2,
            content: ["High Priority"],
          },
        },
      },
    },
  };

  // setFormattedData(data);
  // setLoading(false);
  // setOptions(newOptions);
  // }

  useImperativeHandle(ref, () => {
      return {
        downloadChartImage() {
          const base64Image = chartRef.current.toBase64Image("image/png", 1);
          return base64Image;
        },
      };
  });

  if (!chartData) {
    return "";
  }

  return (
    <>
      <Line ref={chartRef} options={newOptions} data={data} height={height} />
    </>
  );
});

export const MyDoc = ({ base64Image }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>
        <View style={styles.pageHeader}>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Helvetica-Bold",
              color: "#08415c",
            }}
          >
            Symptom Burden Graph
          </Text>
          <Text
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "Helvetica-Bold",
              color: "#08415c",
            }}
          >
            Nutri-Q
          </Text>
        </View>

        <Image style={styles.chartImage} src={base64Image} />

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the
            Food and Drug Administration. These products or protocols are not intended to diagnose,
            treat, cure or prevent any disease or be used as a substitute for appropriate care of a
            qualified health practitioner.
          </Text>
          <Text>{moment().format("MMM DD, YYYY")}</Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: { backgroundColor: "#fff", flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  chartImage: {
    width: "100%",
    height: "auto",
  },
});
