import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Form, ProgressBar, Row, Accordion } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import StepWizard from "react-step-wizard";
import ToDoPage from "./Component/ToDoPage";
import { decode } from "html-entities";
import { uniqId, validatePageConditions } from "helpers/common";
import { NAQV1_ID, NAQV2_ID } from "common/constants";
import { cloneDeep, isEmpty } from "lodash";
import parse from "html-react-parser";

const AddDataForm = ({
  loading,
  todoId,
  todoPages,
  handleSubmit,
  onToDoCompleted,
  todoFormDetails,
  initialStep,
  allQuestionAnswers,
  StartStep,
  FinishStep,
  handleSubmitTodo,
}) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [questionAnswers, setQuestionAnswers] = useState(allQuestionAnswers);
  // const [pages, setPages] = useState([]);

  const pages = useMemo(() => {
    if(isEmpty(questionAnswers) || isEmpty(todoPages)) {
      return [];
    }
    let copyPages = cloneDeep(todoPages);
    copyPages = copyPages?.map((page) => {
      let copyPage = { ...page };
      // check if page should be displayed or not
      let copyQuestionAnswers = cloneDeep(questionAnswers);
      copyPage.visible = validatePageConditions(page, copyQuestionAnswers);
      return copyPage;
    });
    return copyPages;
  }, [questionAnswers, todoPages])

  useEffect(() => {
    setQuestionAnswers(allQuestionAnswers);
  }, [allQuestionAnswers]);

  useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  let noTransitions = {
    enterRight: "",
    enterLeft: "",
    exitRight: "",
    exitLeft: "",
  };

  const handleStepChange = async (step) => {
    setActiveStep(step);
  };

  if (loading || !todoId) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className="myClients todo-form">
        <Row className="align-items-start">
          <Col lg="7" className="my-2">
            <div className="form-details">
              {/* {todoFormDetails.form_name && (
                <h2 className="m-0 fw-bold">{todoFormDetails.form_name}</h2>
              )} */}
              {todoFormDetails?.form_desc && (
                <div className="m-0 py-2">{parse(todoFormDetails?.form_desc)}</div>
              )}
            </div>

            <StepWizard transitions={noTransitions} initialStep={activeStep}>
              {StartStep && React.cloneElement(StartStep)}
              {pages &&
                pages.length > 0 &&
                pages.map((page, i) => {
                  if (page.visible === false) {
                    return null;
                  }
                  return (
                    <ToDoPage
                      todoId={todoId}
                      key={page.id}
                      pageDetails={page}
                      onStepChange={handleStepChange}
                      handleSubmit={handleSubmit}
                      todoFormDetails={todoFormDetails}
                      onToDoCompleted={onToDoCompleted}
                      questionAnswers={questionAnswers}
                      setQuestionAnswers={setQuestionAnswers}
                      isPageVisible={page.visible}
                    />
                  );
                })}
              {/* {FinishStep && <FinishStep handleSubmit={handleSubmitTodo} />} */}
              {FinishStep && React.cloneElement(FinishStep)}
            </StepWizard>
          </Col>
          <Col lg="4" className="my-2 sticky-top ProgressBarBox">
            <div className="commonCardBox bg-white">
              <div className="top py-2 px-3 border-bottom">
                <h3 className="m-0 py-2 fw-sbold">Progress</h3>
              </div>
              <div className="CardBody py-3 px-3 commonContent">
                <ProgressBar now={(Number(activeStep) / pages?.length) * 100} />
                <ul className="list-unstyled ps-0 mb-0 py-2 mt-3">
                  {pages &&
                    pages.length > 0 &&
                    (function () {
                      let stepNumber = 0;
                      return pages.map((page, i) => {
                        if (page.visible === false) {
                          return null;
                        }
                        stepNumber++;
                        return (
                          <li
                            key={`step-${stepNumber}`}
                            className={`py-2 position-relative d-flex align-items-start ${
                              activeStep >= stepNumber && "active"
                            }`}
                          >
                            <span className="icn me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                              >
                                <circle cx="5.55629" cy="5.40199" r="5.235" fill="#f4694b" />
                              </svg>
                            </span>
                            <h4 className="m-0">
                              Step {stepNumber}
                              {![NAQV1_ID, NAQV2_ID].includes(todoFormDetails?.id) && (
                                <p className="text-muted d-inline-block ms-2 fw-light mb-0">
                                  {page.page_title && `(${decode(page.page_title)})`}
                                </p>
                              )}
                            </h4>{" "}
                          </li>
                        );
                      });
                    })()}
                  {/* <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepOne && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">Start</h4>{" "}
                  </li>
                  <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepTwo && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">
                      Step 1
                      <p className="text-muted d-inline-block ms-2 fw-light mb-0">
                        (Gender)
                      </p>
                    </h4>{" "}
                  </li>
                  <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepThree && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">
                      Step 2
                      <p className="text-muted d-inline-block ms-2 fw-light mb-0">
                        (NAQ)
                      </p>
                    </h4>{" "}
                  </li>
                  <li
                    className={`py-2 position-relative d-flex align-items-start ${
                      status.stepFour && "active"
                    }`}
                  >
                    <span className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <circle
                          cx="5.55629"
                          cy="5.40199"
                          r="5.235"
                          fill="#f4694b"
                        />
                      </svg>
                    </span>
                    <h4 className="m-0">Finish</h4>{" "}
                  </li> */}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    allQuestionAnswers: state.ToDo.currentTodoData.allQuestionAnswers,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddDataForm);
