import React from "react";
import { Dropdown } from "react-bootstrap";
import { DATE_FORMAT } from "helpers/common";
import { Link } from "react-router-dom";

const DocumentList = ({ data, owner, isLoading, handleViewDoc, onClickRemove }) => {
  return (
    <>
      <div className="py-2">
        <div className="commonCardBox">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-transparent">
                <tr>
                  <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                    Document Name
                  </th>
                  <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                    Added On
                  </th>
                  <th className="p-3 fw-normal text-dark fw-bold text-uppercase">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={3} className="border-0 text-center">Loading...</td>
                  </tr>
                ) : data?.length ? (
                  data.map((document, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="px-3 py-2 border-0">
                          <p className="theme-clr m-0 ws-normal fw-bold">
                            {document.document_name}
                          </p>
                        </td>
                        <td className="px-3 py-2 border-0">
                          <p className="theme-clr m-0">{DATE_FORMAT(document.created_at)}</p>
                        </td>
                        <td className="px-3 py-2 border-0">
                          <Dropdown>
                            <Dropdown.Toggle
                              className=" bg-white btnSm text-uppercase text-dark fw-sbold border pe-5 position-relative"
                              id="dropdown-basic"
                            >
                              Options
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <ul className="list-unstyled ps-0 mb-0 noLastBorder">
                                <li
                                  onClick={() => {
                                    handleViewDoc(document);
                                  }}
                                >
                                  <a
                                    href={document.document}
                                    className="text-dark text-uppercase fw-sbold text-dark px-3 py-1 d-block"
                                    target="_blank"
                                  >
                                    View Document
                                  </a>
                                </li>
                                {owner === "client" && (
                                  <li>
                                    <Link
                                      onClick={() => {
                                        onClickRemove(document.id);
                                      }}
                                      className="text-dark text-uppercase fw-sbold text-dark px-3 py-1 d-block"
                                    >
                                      Remove
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  }
                  )) : (
                  <tr>
                    <td colSpan={3} className="border-0 text-center">No documents found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default DocumentList;