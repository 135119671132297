import { GET_DOCUMENT_LIST } from "store/actions/document";

const initialState = {
    isAuthenticated: false,
    documents: [],
    loader:false,
};

//get 
const getDocumentsList = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        isAuthenticated: true,
        documents: data.body,
    };
  return stateObj;
};

const Document = (state = initialState, { type, payload = null }) => {
    switch (type) {
      case GET_DOCUMENT_LIST:
        return getDocumentsList(state, payload);
      default:
        return state;
    };
}

export default Document;