import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Accordion,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import * as Path from "Routes/paths";

// css
import "./Sidebar.scss";

// images
import logo from "../../Assets/Images/logo.png";
import OrangeBg from "../../Assets/Images/orangeBg.png";
import dp from "../../Assets/Images/AuthImg1.png";

// icns
import { ReactComponent as DashIcn } from "../../Assets/Images/icn/Dashboard.svg";
import { ReactComponent as CloseBtn } from "../../Assets/Images/icn/close.svg";
import { ReactComponent as ResearchIcn } from "../../Assets/Images/icn/research.svg";
import { ReactComponent as SettingIcn } from "../../Assets/Images/icn/setting.svg";
import { ReactComponent as ThumbIcn } from "../../Assets/Images/icn/thumb.svg";
import { ReactComponent as BoardIcn } from "../../Assets/Images/icn/clipboard.svg";
import { ReactComponent as DocIcn } from "../../Assets/Images/icn/docWhite.svg";
import { ReactComponent as FolderOpenIcn } from "../../Assets/Images/icn/FolderOpen.svg";

import { logout } from "store/services/authService";
import * as actions from "store/actions";
import { HEALTH_COATCH_CAT_ID } from "common/constants";
import { profile } from "store/services/profileService";

const Sidebar = ({ dispatch, user, userCategory, planStatus, showSidebar, setSidebar, anytimeLogginEnabled }) => {
  const navigate = useNavigate();

  useEffect(() => {
    async function init() {
      try {
        dispatch(actions.persist_store({ loader: true }));
        dispatch(profile());
        dispatch(actions.persist_store({ loader: false }));
      } catch (error) {
        console.log("error getting dashboard data", error);
        dispatch(actions.persist_store({ loader: false }));
      }
    }

    init();
  }, [])
  
  const handleSidebar = () => {
    setSidebar(!showSidebar);
  };

  //logout function
  const handleLogout = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      const authToken = user ? user?.access_token : "";
      await dispatch(logout(authToken)).then((res) => {
        navigate("/login");
        dispatch(actions.persist_store({ loader: false }));
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };
  return (
    <>
      <div className={`sidebar py-2 pt-4 ${showSidebar && "active"}`}>
        <Button
          onClick={handleSidebar}
          className="border-0 p-0 position-absolute closeBtn rounded-0 d-flex align-items-center justify-content-center d-lg-none"
          variant="transparent"
          style={{ right: "0", top: "0" }}
        >
          <CloseBtn />
        </Button>
        <div className="top py-3 mb-4 px-3">
          <Link to={"/dashboard"} className="text-dark logo">
            <img src={logo} alt="" className="img-fluid" />
          </Link>
        </div>
        <div className="LinksWrpper d-flex flex-column justify-content-between">
          <Accordion>
            <div className="LinksInner">
              <ul className="list-unstyled ps-0 mb-0">
                <li className="my-2 position-relative px-3">
                  {/* <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Dashboard</Tooltip>}
                  > */}
                  <NavLink
                    to={user?.terms_accepted === "yes" ? "/dashboard" : "#"}
                    className={`${user?.terms_accepted === "yes" ? "link" : "link-disabled"} d-flex position-relative align-items-center text-white`}
                  >
                    <span className="icn me-3 me-lg-0">
                      <DashIcn />
                    </span>
                    Dashboard
                  </NavLink>
                  {/* </OverlayTrigger> */}
                </li>
                <li className="my-2 position-relative px-3">
                  {/* <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">To Do</Tooltip>}
                  > */}
                  <NavLink
                    to={user?.terms_accepted === "yes" ? "/to-do" : "#"}
                    className={`${user?.terms_accepted === "yes" ? "link" : "link-disabled"} d-flex position-relative align-items-center text-white`}
                  >
                    <span className="icn me-3 me-lg-0">
                      <BoardIcn />
                    </span>
                    To Do
                  </NavLink>
                  {/* </OverlayTrigger> */}
                </li>
                <li className="my-2 position-relative px-3">
                  {/* <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Recommendation</Tooltip>}
                  > */}
                  <NavLink
                    to={
                      user?.terms_accepted === "yes" ?
                        userCategory === HEALTH_COATCH_CAT_ID ? "/action-plans" : "/recommendation"
                      :
                        "#"
                    }
                    className={`${user?.terms_accepted === "yes" ? "link" : "link-disabled"} d-flex position-relative align-items-center text-white`}
                  >
                    <span className="icn me-3 me-lg-0">
                      <ThumbIcn />
                    </span>
                    {userCategory === HEALTH_COATCH_CAT_ID ? "Action Plans" : "Recommendation"}
                  </NavLink>
                  {/* </OverlayTrigger> */}
                </li>
                {/* <li className="my-2 position-relative px-3">
                  <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Research</Tooltip>}
                  >
                    <NavLink
                      to={"/food-research"}
                      className="link d-flex position-relative align-items-center text-white"
                    >
                      <span className="icn me-3 me-lg-0">
                        <ResearchIcn />
                      </span>
                      Research
                    </NavLink>
                  </OverlayTrigger>
                </li> */}
                {(!anytimeLogginEnabled || anytimeLogginEnabled === "no") && (
                  <li className="my-2 position-relative px-3">
                    {/* <OverlayTrigger
                      placement="right"
                      arrow={false}
                      overlay={<Tooltip id="tooltip">My Log</Tooltip>}
                    > */}
                    <NavLink
                      to={user?.terms_accepted === "yes" ? "/my-log" : "#"}
                      className={`${user?.terms_accepted === "yes" ? "link" : "link-disabled"} d-flex position-relative align-items-center text-white`}
                    >
                      <span className="icn me-3 me-lg-0">
                        <DocIcn />
                      </span>
                      My Log
                    </NavLink>
                    {/* </OverlayTrigger> */}
                  </li>
                )}

                {/* {anytimeLogginEnabled === "yes" && (
                  <li className="my-2 position-relative px-3">
                    <Accordion.Item
                      className="border-0 bg-transparent"
                      eventKey="0"
                    >
                      <Accordion.Header className=" position-relative text-white">
                        <span className="icn mb-2">
                          <DocIcn />
                        </span>
                        My Log
                      </Accordion.Header>
                      <Accordion.Body className="py-0 px-0">
                        <ul className="list-unstyled ps-0 mb-0">
                          <li className="">
                            <NavLink
                              // onClick={handleDropdownLinkClick}
                              to={Path.logMeal}
                              className=""
                            >
                              Log a Meal or Snack
                            </NavLink>
                          </li>
                          <li className="">
                            <NavLink
                              // onClick={handleDropdownLinkClick}
                              to={Path.logFeeling}
                              className=""
                            >
                              Log a Feeling or Note
                            </NavLink>
                          </li>
                          <li className="">
                            <NavLink
                              // onClick={handleDropdownLinkClick}
                              to={Path.viewLog}
                              className=""
                            >
                              View Log
                            </NavLink>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </li>
                )} */}

                {/* <li className="my-2 position-relative px-3">
                  <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">My Clients</Tooltip>}
                  >
                    <NavLink
                      to={"/my-clients"}
                      className="link d-flex position-relative align-items-center text-white"
                    >
                      <span className="icn me-3 me-lg-0">
                        <UsersIcn />
                      </span>
                      My Clients
                    </NavLink>
                  </OverlayTrigger>
                </li>
                <li className="my-2 position-relative px-3">
                  <Dropdown className=" text-center">
                    <Dropdown.Toggle
                      variant="transparent"
                      className="position-relative noAfter link"
                      id="dropdown-basic"
                    >
                      <DocIcn />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="border-0">
                      <ul className="list-unstyled ps-0 mb-0">
                        <li className="">
                          <Link
                            to="/my-docs"
                            className="text-white fw-sbold px-3 py-1 d-flex"
                          >
                            My Docs
                          </Link>
                        </li>
                        <li className="">
                          <Link
                            to="/my-forms"
                            className="text-white fw-sbold px-3 py-1 d-flex"
                          >
                            My Forms
                          </Link>
                        </li>
                        <li className="">
                          <Link
                            to="/resources"
                            className="text-white fw-sbold px-3 py-1 d-flex"
                          >
                            Resources
                          </Link>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="my-2 position-relative px-3">
                  <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Research</Tooltip>}
                  >
                    <NavLink
                      to={"/food-research"}
                      className="link d-flex position-relative align-items-center text-white"
                    >
                      <span className="icn me-3 me-lg-0">
                        <ResearchIcn />
                      </span>
                      Research
                    </NavLink>
                  </OverlayTrigger>
                </li>
                <li className="my-2 position-relative px-3">
                  <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Appointment</Tooltip>}
                  >
                    <NavLink
                      to={"/appointment"}
                      className="link d-flex position-relative align-items-center text-white"
                    >
                      <span className="icn me-3 me-lg-0">
                        <CalenderIcn />
                      </span>
                      Appointment
                    </NavLink>
                  </OverlayTrigger>
                </li> */}
                <li className="my-2 position-relative px-3">
                  <NavLink
                    to={user?.terms_accepted === "yes" ? Path.documents : "#"}
                    className={`${user?.terms_accepted === "yes" ? "link" : "link-disabled"} d-flex position-relative align-items-center text-white`}
                  >
                    <span className="icn me-3 me-lg-0">
                      <FolderOpenIcn
                        style={{ width: "28px", height: "28px" }}
                      />
                    </span>
                    Documents
                  </NavLink>
                </li>
              </ul>
            </div>
          </Accordion>
          <div className="bottom px-2 pb-3">
            <Dropdown className="profileDropdown text-center">
              <Dropdown.Toggle
                variant="transparent"
                className="position-relative p-0 noAfter"
                id="dropdown-basic"
              >
                <img src={dp} alt="" className="img-fluid h-100 w-100" />
                <span className="position-absolute d-flex align-items-center justify-content-center">
                  <SettingIcn />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="border-0">
                <ul className="list-unstyled ps-0 mb-0">
                  {user && user?.is_multiple_profiles === "yes" && (
                    <li className="">
                      <Link
                        to={Path.switchProfileDashboard}
                        className=" fw-sbold px-3 py-1 d-flex"
                      >
                        Switch Profile
                      </Link>
                    </li>
                  )}
                  <li className="">
                    <Link
                      to={user?.terms_accepted === "yes" ? "/my-account" : "#"}
                      className=" fw-sbold px-3 py-1 d-flex"
                    >
                      My Account
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      onClick={handleLogout}
                      className="text-dark fw-sbold px-3 py-1 d-flex"
                    >
                      Sign out
                    </Link>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            {/* <div
              className="cardCstm p-3"
              style={{
                backgroundImage: `url(${OrangeBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="top d-flex align-items-start justify-content-between gap-10">
                <div className="imgWrp">
                  <img src={dp} alt="" className="img-fluid" />
                </div>
                <div className="right">
                  <Dropdown className="rightMenu">
                    <Dropdown.Toggle
                      variant="transparent"
                      className="noAfter p-1 px-2 border-white rounded"
                      id="dropdown-basic"
                    >
                      <V3DotsIcn />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="noLastBorder">
                      <Link className="fw-sbold dropdown-item" to="/my-account">
                        My Account
                      </Link>
                      <Dropdown.Item className="fw-sbold" href="#/action-2">
                        Help
                      </Dropdown.Item>
                      <Dropdown.Item className="fw-sbold" href="#/action-3">
                        Whats New
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="content pt-3">
                <h6 className="fw-bold text-white m-0">John Smith</h6>
                <p className="m-0 text-white">joh_smith@demomail.com</p>
                <div className="btnWrp mt-2 d-flex align-itmes-center gap-10">
                  <Button className="d-flex fw-sbold align-items-center text-dark justify-content-center bg-white border-white">
                    Go to Profile
                  </Button>
                  <Link
                    to="/login"
                    className="text-white underline fw-sbold m-0 d-flex align-items-center "
                  >
                    {" "}
                    Logout
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Profile.user,
    userCategory: state.Profile.user?.user_category,
    anytimeLogginEnabled: state.Profile.user?.client_anytime_logging,
    planStatus: state.Profile?.user?.is_plan_active,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Sidebar);
