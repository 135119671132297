import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from 'store/actions';
import { getPractitionerSettings } from "store/services/authService";
import { addDocument, deleteDocument, getDocumentList, markDocumentViewed } from "store/services/documentService";
import DocumentList from "./Component/DocumentList/Index";
import { UploadMediaFile } from "helpers/UploadFile";
import AddDocumentModal from "./Component/Modals/AddDocumentModal";
import { ReactComponent as PlusIcn } from "Assets/Images/icn/plus.svg";
import { IS_BASE64 } from "helpers/common";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";

const Documents = ({
  dispatch,
  practSettings,
  practitionerOwnedDocs,
  clientOwnedDocs,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [showAddDocumentPopup, setShowAddDocumentPopup] = useState(false);
  const practName = practSettings?.display_name;

  useEffect(() => {
    getDocuments();
    getPractSetttings();
  }, [])
  
  const getDocuments = async () => {
    try {
      setLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getDocumentList());
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
    } catch(err) {
      console.log("Error occurred while fetching ToDo listing", err);
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
    }
  }

  const handleDeleteDocument = async (id) => {
    try {
      let confirmed = await CONFIRM_POPUP()
      if (!confirmed) {
        return;
      }

      dispatch(actions.persist_store({ loader: true }));
      await deleteDocument(id);
      await getDocuments();
      dispatch(actions.persist_store({ loader: false }));
      SUCCESS_MESSAGE("Document deleted successfully");
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error deleting document", err);
    }
  };

  const handleViewDocument = async (document) => {
    try {
      if(document?.client_viewed_at || document?.client_id === document?.owner_id) {
        return;
      }

      let payload = {
        id: document.id,
        values: {
          client_viewed_at: new Date()
        }
      }
      dispatch(actions.persist_store({ loader: true }));
      await markDocumentViewed(payload);
      getDocuments();
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.error("Error updating client visibility", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const getPractSetttings = async () => {
    try {
      await dispatch(getPractitionerSettings());
    } catch (err) {
      console.log("Error fetching pracitioner settings", err);
    }
  }

  const addDocumentClient = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      dispatch(actions.persist_store({ loader: true }));
      let fileUploadResult = null;
      if(IS_BASE64(values.file)){
        fileUploadResult = await UploadMediaFile(dispatch, values.file, values.extension, values.file_type, 'documents');
      }
      if(fileUploadResult?.success === true && fileUploadResult?.body?.url) {
        let postData = {
          document_name : values?.document_name,
          document: fileUploadResult?.body?.url,
          client_visible : values.client_visible,
        }
        let response = await addDocument(postData);
        if(response.success === true && response.message) {
          setShowAddDocumentPopup(false);
          SUCCESS_MESSAGE(response.message)
        }
        await getDocuments();
      }
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err, "==============================");
      setSubmitting(false);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const toggleAddDocumentPopup = () => {
    setShowAddDocumentPopup(!showAddDocumentPopup);
  };

  return (
    <>
      <AddDocumentModal
        showPopup={showAddDocumentPopup}
        setShowPopup={setShowAddDocumentPopup}
        handleSubmit={addDocumentClient}
      />
      <section className="toDo position-relative">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <div className="d-flex justify-content-between commonContent py-2">
                  <div>
                    <h3 className="m-0 text-uppercase">Documents uploaded by you</h3>
                    <p className="m-0 py-1">
                      Below are the documents, uploaded by you.
                    </p>
                  </div>
                  <div>
                    <Button
                      onClick={toggleAddDocumentPopup}
                      className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    >
                      <span className="icn me-2">
                        <PlusIcn />
                      </span>
                      Add a new Document
                    </Button>
                  </div>
                </div>
              </div>
              <DocumentList
                data={clientOwnedDocs}
                owner={"client"}
                isLoading={isLoading}
                onClickRemove={handleDeleteDocument}
                handleViewDoc={handleViewDocument}
              />
            </Col>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <div className="commonContent py-2">
                  {practName && (
                    <>
                      <h3 className="m-0 text-uppercase">Documents shared by {practName}</h3>
                      <p className="m-0 py-1">
                        Below are the documents, shared by {practName}.
                      </p>
                    </>
                  )}
                </div>
              </div>
              <DocumentList
                data={practitionerOwnedDocs}
                owner={"practitioner"}
                isLoading={isLoading}
                onClickRemove={handleDeleteDocument}
                handleViewDoc={handleViewDocument}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  let allDocuments = state.Document.documents;

  return {
    practitionerOwnedDocs: allDocuments?.practitioner_owned,
    clientOwnedDocs: allDocuments?.client_owned,
    practSettings: state.Auth.practitionerSettings,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Documents);